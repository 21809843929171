.display_none {
  display: none !important;
}


.phonefield_container{
  border: 1px solid #ced4da;
    border-radius: .25rem;
}
.phonefield_select{
    max-width: 28px;
    padding: 0 0px 0 10px !important;
    -webkit-appearance: none;
    border-right: 1px solid #ced4da !important;
    height: 37px !important;
    border-radius: 0;
    width: 34px !important;
    border: navajowhite;
}

.App {
  text-align: center;
}

.form-select {
  font-size: 12px;
}

.form-control {
  font-size: 12px;
}

select option:first-child {
  font-weight: bold;
  background:#ccccccba;

  }
  select option {
  color: #262626  !important;
  }


.no_conversation_found {
  text-align: center;
  margin: 9%;
}

/* auto search by ajax */
.auto_search_ul {
  list-style: none;
  padding-left: 5px;
  position: absolute;
  background: #fff;
  z-index: 99;
  width: 213px;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow: scroll;
}


ul.auto_search_ul li {
  padding: 4px 5px;
  border-bottom: 1px solid #ccc;
}

.border_bottom_none {
  border: navajowhite;
}

.cursor_pointer {
  cursor: pointer;
}

.livechatbox {
  z-index: 9;
}

.border_blue {
  border: 1px solid #240c37;
}

.workexp-description {
  background: #cccccc38;
  padding: 17px;
}

.text-orange {
  color: #f8782e;
}

.font13px {
  font-size: 13px;
}

.text-blueshade {
  color: #614578 !important;
}

.searchresultitem a {
  color: #f76d1d !important;
}

.laodingdata {
  text-align: center;
  padding: 15%;
  background: #fff;
}

.laodingdata img {
  width: 100px;
}

.font-bold {
  font-weight: bold;
}

.cursor_pointer.chatlist_title.active {
  background: #cccccc63;
}

.chat_textarea {
  width: 100%;
  height: 39px;
  border: navajowhite;
  background: #f2f2f2;
  padding-top: 4px;
}

.cahtfooter_modal {
  padding: 5px 2px;
  background: #f2f2f2;
  border-radius: 0px;
}

.modal_back {
  position: relative;
  margin-right: 80%;
}

.chat_textarea_modal {
  font-size: 15px;
  ;
}

.text-green {
  color: #6c9977;
}

.text-red {
  color: #ea0c41;
}

.salaryfield2 {
  position: relative;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;
}

.close_reference_btn {
  float: right;
  position: absolute;
  margin-left: 66%;
  margin-top: -14px;
  border-radius: 47%;
  font-size: 10px;
  padding: 1px 8px;
  color: #fff;
}


.model_loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000eb;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.model_loader img,
.width-100 {
  width: 70px;
}

.width-100px {
  width: 100px;
}

.width-100percent {
  width: 100%;
}

.payment_card {
  height: 50px;
  margin-bottom: 15px;
}

.bgbrown {
  background: #cccccc29;
  padding: 10px;
}


.faicon {
  font-size: 20px;
  color: #f8782e;
  margin-top: 1px;
}

.bgdarkgreen {
  background: #198754;
}


.admin_chat_window {
  position: absolute;
  right: 12%;
  z-index: 99;
}

.width-120 {
  width: 120px !important;
}

#navbarSupportedContent .dropdown-menu .nav-item .nav-link {
  padding: 5px 1rem;
}


.withdrawfrom {
  /* margin: 0 auto; */
  background: #fff;
  padding: 40px 43px;
  border-radius: 24px;
  /* max-width: 500px; */
}


.scheduleForm .react-datepicker-wrapper {
  width: 100%;
}


.fontstyle{
  font-size: 20px;
    font-weight: 600;
}

/****************innserpage loader******************/
.loader_container {
  height: 400px;
  background: #fff;
  padding-top: 18%;
}

.loader_container_fsmodel {
  height: 500px;
  background: #fff;
  padding-top: 40%;
}

.mt45px {
  margin-top: 45px;
  ;
}


.date-picker-container {
  position: relative;
}

.calendar-icon {
  position: absolute;
  top: 68%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;
}


/*******************end ***************/

.bgpageload {
  background: #0000003d;
}



.salaryfield2 .form-control {
  font-size: 14px;
  padding: 5px;
  padding-right: 5px !important;
  color: #555;
}


.document_list {
  text-decoration: none;
}

.document_list p {
  font-size: 14px;
  color: #61729A;
  margin-bottom: inherit;
}

.card-box .row.editfieldss>.col-md-7 {
  padding: 15px 15px;
  margin: 7px 7px;
  background: #f9f9fe;
  border-radius: 4px;
}

/* auto search by ajax end */

.float-right {
  float: right;
}

.onhoverinfo {
  z-index: 9;
  font-size: 13px;
  font-weight: 100;
  font-family: 'hellixmedium';
}


.commonchecbox2 {
  margin-bottom: 4px;
  margin-right: 8px;
  color: #614578;
  font-size: 13px;
}

.paddingofcheckbox {
  padding-left: 24px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.stepscont label.form-label {
  margin-bottom: 2px;
  font-size: 13px;
  font-family: 'hellixmedium';
}


.editfile {
  display: none;
}

.captured_image {
  width: 100%;
}


.modal_close_btn {
  position: absolute;
  margin-left: 93%;
  margin-top: -23px;
  background: #f76d1d;
  color: #fff;
}

.rememberme {
  font-size: 13px;
  font-family: 'hellixmedium';
  margin-bottom: 5px;
  color: #f76d1d;
  text-decoration: none;
  cursor: pointer;
}



.margintop {
  margin-top: -5px;
  position: absolute;
}

.float-right {
  float: right;
}

.cms_faq_type {
  width: 100px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;

}

.ml400px {
  margin-left: 400px;
}

.backbtnchkout2 {
  position: absolute;
  margin-left: 94px;
  margin-top: -38px;
}


.invite_other_options {
  display: flex;
  justify-content: space-between;
  /* Adjust as needed */
}


.invite_share-option {
  display: inline-block;
}

.inviteurl_copybtn {
  color: rgb(255, 255, 255);
  background-color: rgb(247 109 29);
  border: none;
  border-radius: unset;
}


.btnrev {
  background: #657699 !important;
  color: #fff !important;
  border-color: #657699 !important;
}

.btnrev:hover {
  background: #f76d1d !important;
  border-color: #f76d1d !important;
}

label.hidden_text {
  display: block;
}

.livechat_icon {
  font-size: 25px;
  color: #f76d1d;
}

.alert-light {
  color: #636464;
  background-color: #f76d1d1a;
  border-color: #fdfdfe;
}

.error-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #dc3545;
}

.nextbtn {
  position: absolute;
  margin-top: -40px !important;
  margin-left: 89px;
}

.search_warning_icon {
  color: #fff;
}


#withdraw_amount_coins .modal-body {
  padding: 0px;
}

#withdraw_amount_coins .modal-content {
  border-radius: 22px;
}


.transection_table table {
  margin-top: 20px;
  border-collapse: collapse;
  /* Collapse the borders between cells */
}





.transection_table td,
.transection_table th {
  border: 1px solid #ccc;
}

.add_bank_details_link {
  color: #f8782f;
  font-size: 17px;
  margin-left: 10px;
}


.alertdanger {
  background: #f8d7da !important;
}

.alertsuccess {
  background: #d4edda !important;
}

.bgdarkred {
  background: #a30f0f;
}

.font10px {
  font-size: 10px;
}

span.infodtlpass {
  top: 20px;
  z-index: 99;
  color: #dc3545;
  right: auto;
}


.keywidthicon {
  width: 24px;
  left: 9px;
  top: 8px;
  position: absolute;
}

@media only screen and (min-width: 992px) {
  .col-md-20 {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-danger {
  color: #f76d1d;
}

.text-ornage {
  color: #f76d1d;
}

.error_message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.success_message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #198754;
}

.add_newjobtitle {
  border-radius: 0px 4px 4px 0px;
}


.no-document-found {
  padding: 50px 0px;
  text-align: center;
}

.cantupload {
  border: 1px solid #cccc;
  padding: 10px;
}

.height-250 {
  height: 250px;
}

.bordercss,
.bordercss .css-13cymwt-control {
  border-color: #dc3545 !important;
}


.page_limit {
  width: 55px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.anc_class {
  color: #0d6efd !important;
}


.myCallContainer2 .sJcgXLqA2Va2eqymgksg {
  display: none;
}

.modal-minheight {
  min-height: 200px;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #ced4da;
}

.invalid-feedback {
  display: none !important;
}

.load_more_exp_link {
  text-align: center;
  font-size: 14px;
  color: #f76d1d !important;
  padding-top: 10px;
  border-top: 1px solid #624a7c;
  cursor: pointer;
}


.text_safron {
  color: #f76d1d !important;
}

.text-right {
  text-align: right;
}

.display_block {
  display: block;
}

.searchresultitem a {
  text-decoration: none;
  color: #5b3e74;
}


.back_to_hist {
  color: #f76d1d;
  margin-right: 7px;
  border: 3px solid;
  padding: 2px 9px;
  border-radius: 50%;
}

img.chat_row_attachment.admin {
  height: 40px;
  margin: 0px 10px;
}


.notification-list {
  min-width: 300px;
  ;
}

#wordkExperienceAddModel form {
  padding: 8px;
  margin: 0px;
}

.uniquenu_error {
  color: red;
  font-size: 11px;
}

.modal-body h4 {
  font-size: unset;
}

.admintbl tr th {
  text-transform: none;
}

.pl-5 {
  padding-left: 5px;
  ;
}

.ml-5 {
  margin-left: 5px;
  ;
}

.mr-5 {
  margin-right: 5px;
}

i.fablue {
  background: #0dcaf0;
  color: #0dcaf0;
  border-radius: 46%;
  font-size: 10px;
}

i.fagreen {
  background: #198754;
  color: #198754;
  border-radius: 46%;
  font-size: 10px;
}

i.fared {
  background: #FF2348;
  color: #FF2348;
  border-radius: 46%;
  font-size: 10px;
}

tr.deactive_tr td {
  color: #ed1c1c;
}

.fabanicon {
  font-size: 24px;
  color: #f8782e;
}

/*-----------*/
.systemupload {
  position: relative;
  display: inline;
}

.systemupload input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.systemupload a {
  /* margin: 0px; */
  display: block;
  display: inline-block;
}


a.chatlist_title.active .chatsinglepnl {
  background: #e9ecef;
}

.chat_row_attachment {
  height: 100px;
  ;
}


.filtericon i {
  padding: 8px;
  margin-left: 10px;
  color: #f76d1d;
  border: 1px solid #ccc;
}

.react-datepicker-wrapper {
  margin-right: 10px;
}

.date_filter_btn {
  font-size: 14px !important;
}

form.searchform.d-flex {
  display: none !important;
}


#contentdata_show {
  background: #cccccc1a;
  padding: 10px;
  border-radius: 11px;
}

.mb-10 {
  margin-bottom: 10px;
}

div#contentdata {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
}

.greenbtn {
  background: #04d060;
  color: #fff !important;
  text-decoration: none !important;
}

.btn-green {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

/*------------------------*/

.Toastify__toast {
  padding: 31px 10px;
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

/*--------------*/
span.star {
  font-size: 32px;
  cursor: pointer;
}

span.star.active {
  color: #f76d1d;
}

.font-14px {
  font-size: 24px !important;
}

.saferonbg {
  background: #f36d1d21
}

.ediiconimg {
  width: 22px
}

.comenticon {
  font-size: 26px;
  color: #f76d1d;
}

.chatsinglepnladmin {
  padding: 10px;
}

.chatsinglepnladmin .chatimg img {
  width: 47px;
  height: 47px;
  border-radius: 50%;
}

.back_to_hist1 {
  color: #f76d1d;
}

.back_to_hist1 i.fa.fa-chevron-left {
  margin-right: 6px;
}

.text-safron {
  color: #f76d1d !important;
}

.text-website {
  color: #212529 !important;
}

.text-decoration-none {
  text-decoration: none
}

.dashboard_tile a {
  color: #000;
  text-decoration: none;
}

tr.active.active_row_class {
  background: #cccccc45;
}

.mr-1 {
  margin-right: 5px;
}

.jobseekertabs .nav-link {
  margin-right: 5px;
}

.livechat_message_body {
  min-height: 300px;
}

.mtminus5px {
  margin-top: -5px;
}


.disableprofilewrapinner input[type="checkbox"] {
  z-index: 99;
}

.veri_unveri_icon {
  width: 22px;
  height: auto !important;
  margin-left: 5px;
  margin-top: -10px;
}

/*-------------------*/
.availibility_active_from{
  border: 1px solid #f33a2d94 !important;
  box-shadow: 1px 1px #f33a2d94 !important;
}
.availibility_active{
  border: 1px solid #f33a2d94;
  box-shadow: 1px 1px #f33a2d94;
}
/*-------------------*/
td.first_class .form-label {
  width: 89px;
}

.accessibility_section {
  margin-right: 70px;
}

.accessibility_label {
  width: 40px;
}

td.first_class {
  width: 200px;
}

/*------------livechatbox--------------*/
.livechatbox img.chatavatar {
  width: 31px;
  height: 31px;
  min-width: 33px;
}

.livechaterror {
  text-align: center;
  font-size: 13px;
  color: red;
  margin-top: -13px;
}

.livechaterror_border {
  border-color: #dc3545;
}

.success_border {
  border-color: #07582f !important;

}
.error_border {
  border-color: #dc3545 !important;
  border: 1px solid #dc3545;
    border-radius: 4px;
}

.ml-1 {
  margin-left: 10px;
}

.ml4px {
  margin-left: 4px;
}

i.fa.fa-check-circle-o {
  font-size: 22px;
  color: #f76d1db3;
}

/*------------child class--------------*/
.main-chatbox .livechatbox:nth-child(2) {
  right: 350px;
}

.main-chatbox .livechatbox:nth-child(3) {
  right: 700px;
}

.main-chatbox .livechatbox:nth-child(4) {
  right: 1050px;
}

.chatheadername {
  position: absolute;
  margin-left: 27%;
  margin-top: 18px;
  color: #fff;
  font-weight: bold;
}

.conversation_close {
  font-size: 14px;
  font-weight: bold;
  background: #f76d1d42;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid;
}

.disableprofilewrapinner {
  border: 1px solid #f76d1d;
  border-radius: 13px;
}

.disable_slider {
  opacity: 0.5;
}

h4.font-bold {
  font-weight: bold;
}

/*----------------------------------------*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*-------------search with select------------------*/


.mt-15px {
  margin-top: -15px;
}

.tblsearch2 input {
  min-width: 200px;
  margin-right: 10px;
}

.react_select {
  font-size: 12px;
}

.css-1dimb5e-singleValue {
  font-size: 13px;
}

.react_select2 {
  font-size: 14px;
}

.add_preffered_job_modal .md12 {
  width: 100%;
}


img.veriunveri-images {
  width: 24px;
}

.admininfo .documentttl.text-capitalize {
  font-size: 12px;
}

img.veriunveri-images.documentverification {
  position: absolute;
  margin-left: 7%;
  margin-top: 0%;
}


.veriunveri-images22 {
  position: absolute;
  margin-left: 7%;
  margin-top: 0%;
}

/***********model css**************/
/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
} */
/*************************/
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

form.row.align-items-start.editfieldss {
  background: #fff;
  margin: 10px;
  margin-top: -14px;
  padding: 30px 10px;
}



a.candidate_chat_btn {
  font-size: 16px;
  margin-top: -10px;
  color: #f76d1d;
}

.jsrprofilemodal .staffnamerating {
  width: calc(100% - 82px);
}

.jsrprofilemodal .chatnowicon {
  margin-top: -37px;
}

img.downloadicon {
  width: 24px;
}

img.block_unblock_icon {
  width: 18px !important;
  margin-top: -1px;
}

.icontxtbtm {
  padding-right: 8px;
}

/* .fade.modal-backdrop.show {
  position: relative;
  z-index: 1;
  opacity: unset;
}
.modal{
  height: auto;
} */

/*  */


.pac-container.pac-logo.hdpi {
  z-index: 99999;
}

/* css for range */


.profilephoto_input {
  position: absolute;
  width: 300px;
  height: 300px;
  margin-left: 20%;
  margin-top: 2%;
  text-align: center;
  z-index: 1;
  opacity: 0;
}

.signuppage_profile {
  width: 150px;
  height: 130px;
  opacity: 0;
  position: absolute;

}



.transectionFormSection{
  width: 100%;
    padding: 10px 21px;
    background: #f77021;
}

.btncolor{
  background: #240c37;
}

.success_div{
  background: #4caf5033 !important;
}

.danger_div{
  background: #a30f0f0f !important;
}

.transection_table th, .transection_table td {
  padding: 6px;
}

/*-------------------*/


.social-login {
  height: 41px;
  border-radius: 4px;
  border: 1px solid #E0E6F6;
  width: 100%;
  line-height: 41px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.social-login img {
  margin-right: 4px;
}
.social-login:hover {
  color: #605dba;
  box-shadow: 0px 10px 20px -5px rgba(10, 42, 105, 0.06);
}




